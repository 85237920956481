import Badge from "components/dist/atoms/Badge";
import Button from "components/dist/atoms/Button";
import Dialog from "components/dist/atoms/Dialog";
import Icon from "components/dist/atoms/Icon";
import Stack from "components/dist/atoms/Stack";
import Text from "components/dist/atoms/Text";

import { useMoveFormElementsDialog } from "./move-form-elements-dialog.hook";
import { MoveDialogProps, MoveFormElementsDialogTreeItemProps } from "./move-form-elements-dialog.types";

export const MoveFormElementsDialog = (props: React.PropsWithChildren<MoveDialogProps>) => {
    const state = useMoveFormElementsDialog(props);

    return <Dialog
        open={state.isDialogOpen}
        onOpenChange={state.onOpenChange}
    >
        {props.children}
        <Dialog.Content className="flex flex-col">
            <Dialog.Header className="shrink-0">
                <Dialog.Title className="truncate">
                    Move {state.totalItems > 1 ? `${state.totalItems} items` : `"${state.singleItemTitle}"`}
                </Dialog.Title>
            </Dialog.Header>
            <Text as="div" className='mx-5 border-b border-gray-neutral-80 pb-3'>
                Current Location: <Badge variant='secondary' className="rounded-lg h-6 text-md font-medium">
                    {state.locationTitle}
                </Badge>
            </Text>
            <div className="px-4 overflow-y-auto">
                {state.formElementsTree?.map((treeElement) => <FormElementTreeItem
                    level={1}
                    onSelect={state.onSelect}
                    movingItem={state.isLoading}
                    onMove={state.onMoveClick}
                    key={treeElement.id}
                    selectedId={state.selectedFolderId}
                    treeElement={treeElement} />)}
            </div>
            <Dialog.Footer className="shrink-0 flex gap-4">
                <Dialog.Close asChild>
                    <Button
                        variant='outline'
                        className="h-10 w-24">
                        Cancel
                    </Button>
                </Dialog.Close>
                <Button
                    onClick={() => state.onMoveClick(state.selectedFolderId)}
                    disabled={!state.selectedFolderId || state.isLoading}
                    className="h-10 w-24">
                    {state.isLoading ? "Moving..." : "Move"}
                </Button>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog>
};

const FormElementTreeItem = (props: MoveFormElementsDialogTreeItemProps) =>
    <ul className="flex flex-col gap-3">
        <li className={` flex flex-col py-1.5 ${![1, 2].includes(props.level) ? "border-l ml-4 pl-4 " : ""}`}>
            <Stack
                onClick={() => props.onSelect(props.treeElement.id)}
                row
                className={`flex items-center cursor-pointer gap-2 h-9 py-1.5 px-3 rounded-md group/move 
                ${props.level > 1 ? "pl-2" : ""} hover:bg-gray-neutral-30
                ${props.selectedId === props.treeElement.id ? "bg-blue-50" : ""}`}>
                {props.level > 1 && <Icon className="text-yellow-60" name="ClientFolder" width={20} height={16} />}
                <Text size='md' weight="medium" className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                    {props.treeElement.title}
                </Text>
                <Button
                    onClick={() => {
                        props.onSelect(props.treeElement.id)
                        props.onMove(props.treeElement.id)
                    }}
                    disabled={props.movingItem}
                    variant='ghost'
                    className="hidden text-primary hover:bg-gray-neutral-30 h-5 py-0 group-hover/move:block">
                    Move here
                </Button>
            </Stack>
            {props.treeElement.children.map((child) => <FormElementTreeItem
                level={props.level + 1}
                onSelect={props.onSelect}
                onMove={props.onMove}
                selectedId={props.selectedId}
                key={child.id}
                treeElement={child} />)}
        </li>
    </ul>