
import ActionAlertDialog from 'components/dist/molecules/ActionAlertDialog';
import RenameAlertDialog from 'components/dist/molecules/RenameAlertDialog';
import AddFilesToUserConfirmation from 'components/dist/organisms/AddFilesToUserConfirmation';
import { GlobalShoeBoxProps } from 'components/dist/organisms/GlobalShoeBox';
import PickLoanToMoveToConfirmation from 'components/dist/organisms/PickLoanToMoveToConfirmation';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';
import { toast as reactToast } from "react-toastify"
import { api } from 'src/api';
import { AppUserDTO2, LoanDto, LoanPhaseCategoryType, ShoeboxItemRequestDto } from 'src/backend';
import { MoveFormElementsDialog } from 'src/components/form-elements/move-form-elements-dialog';
import { RoleTypeLabel } from 'src/constants/loan';
import { QUERY_SHOEBOX_ITEM_PREVIEW_ID } from 'src/constants/shoebox';
import { UPLOADING_TOAST_ID } from 'src/constants/toast';
import { DEFAULT_TOAST_DURATION } from 'src/constants/ui';
import { useUser } from 'src/hooks/use-user';
import { getString } from 'src/i18n/labels';
import { useNotifyCompanyShoeBoxMutation } from 'src/services/companyApi';
import { useCreateShoeBoxItemMutation, useMarkShoeboxItemForDeleteMutation, useUpdateShoeBoxItemMutation } from 'src/services/lenderShoeBoxApi';
import { useGetLoansViewQuery } from 'src/services/loanApi';
import { useGenerateElementsZipMutation, useGenerateZipUserShoeboxItemsMutation } from 'src/services/packageApi';
import { taskApi } from 'src/services/taskApi';
import { getLoan } from 'src/slices/loan';
import { createLoanShoeBoxItem, getLoanShoeBoxItems } from 'src/slices/shoebox';
import { getAllLoanUsersTasks } from 'src/slices/task';
import { toggleDrawer } from 'src/slices/ui';
import { useDispatch } from 'src/store';
import { FileUploading } from 'src/types/formelement';
import { isZipFile } from 'src/utils/file/is-zip-file';
import { uploadFileToDocumentId } from 'src/utils/form-element/upload-file-to-ducoment-id';
import { generateUUID } from 'src/utils/generate-uuid';
import { getInitials } from 'src/utils/get-initials';
import { filterActivePhaseCategory } from 'src/utils/loan/filter-active-phase-category';
import { getReviewStatusChipLabel } from 'src/utils/loan/get-review-status-chip-label';
import { getReviewStatusTooltip } from 'src/utils/loan/get-review-status-tooltip';
import { pluralize } from 'src/utils/pluralize';
import { toast } from 'src/utils/toast';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';
import { getFoldersAndFiles } from 'src/utils/zip';
import { useMediaQuery } from 'usehooks-ts';

import { ToastCreatingElement, ToastFile, UploadingToast } from '../dashboard-sidebar-shoebox/UploadingToast';

type UploadStatus = 'SUCCESS' | 'ERROR' | 'CANCEL';

const AbortReason = {
    "SINGLE": "single",
    "ALL": "all"
}


const transformUser = (user: AppUserDTO2): GlobalShoeBoxProps['loans'][0]['users'][0] => {
    if (!user) {
        return null
    }
    return ({
        id: user.id,
        givenName: user.givenName,
        familyName: user.familyName,
        name: getUserDisplayName(user),
        email: user.emailAddress,
        avatarUrl: "",
        filesCount: 0,
        initials: getInitials(getUserDisplayName(user)),
        role: RoleTypeLabel(user.loggedCompanyRole)
    })
}

export const transformedLoanDtoToLoan = (loans: LoanDto[], userId: string): GlobalShoeBoxProps['loans'] => {
    return loans.map<GlobalShoeBoxProps['loans'][0]>((loan) => ({
        id: loan.id,
        shortCode: loan.shortCode,
        projectName: loan.projectName,
        loanPurpose: loan.loanPurpose,
        loanType: loan.templateProjectName,
        iAmAdded: loan.loanRoles.some((loanRole => loanRole.user.id === userId)),
        loanStatus: {
            tooltip: getReviewStatusTooltip(loan.reviewStatus),
            status: loan.reviewStatus,
            label: getReviewStatusChipLabel(loan.reviewStatus),
        },
        users: loan.loanRoles.map(loanRole => ({
            id: loanRole.user.id,
            givenName: loanRole.user.givenName,
            familyName: loanRole.user.familyName,
            initials: getInitials(getUserDisplayName(loanRole.user)),
            avatarUrl: "",
            filesCount: 0,
            name: getUserDisplayName(loanRole.user),
            email: loanRole.user.emailAddress,
            role: RoleTypeLabel(loanRole.role)
        }))
    }))
}

const TOAST_DURATION = 10_000;

const ShoeboxItemViewerContext = React.createContext<{
    moveToLoanPackage: {
        loanId: string | null,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
    },
    stagedShoeBoxItemsToDelete: GlobalShoeBoxProps['folders'][0]['files']
    stagedShoeBoxItemToRename: GlobalShoeBoxProps['folders'][0]['files'][0] | null
    pickLenderLoan: {
        loan: GlobalShoeBoxProps['loans'][0] | null,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
    }
    onRenameShoeBoxFile: (file: GlobalShoeBoxProps['folders'][0]['files'][0], newName: string) => Promise<void>
    onConfirmDeleteShoeBoxItems: (file: GlobalShoeBoxProps['folders'][0]['files']) => Promise<void>
    loans: GlobalShoeBoxProps['loans']
    me: GlobalShoeBoxProps['me'] | null
    onDownloadShoeboxItemsAsZip: (files: GlobalShoeBoxProps['folders'][0]['files']) => void
    onOpenShoeBoxFileInNewTab: (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => void
    onAddFilesToUserShoeBox: (files: File[], rejectedFiles: File[], userId: string) => void
    user: AppUserDTO2 | null;
    onFilesDrop: (args: { files: File[], userId: string, loanId: string, location: string }) => Promise<void>
    onMoveElementBackToShoeBox: (files: GlobalShoeBoxProps['folders'][0]['files']) => Promise<void>
    onMoveShoeboxItemsToUser: (userId: string, userDisplayName: string, files: GlobalShoeBoxProps['folders'][0]['files']) => Promise<void>
    onMoveItemsBackToShoeBox: (files: GlobalShoeBoxProps['folders'][0]['files']) => Promise<void>
    onMoveItemsBackToLoanShoeBox: (files: GlobalShoeBoxProps['folders'][0]['files'], userDisplayName: string) => Promise<void>
    onMoveShoeBoxFilesToLoan: (loan: GlobalShoeBoxProps['loans'][0], user: GlobalShoeBoxProps['loans'][0]['users'][0], files: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => Promise<void>
    onSetPickLenderLoan: (loan: GlobalShoeBoxProps['loans'][0] | null, files: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => Promise<void>
    setMoveToLoanShoeBoxFiles: (files: GlobalShoeBoxProps['folders'][0]['files']) => void
    setMoveToLoanPackage: (loanId: string | null, files: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => void
    setStagedShoeBoxItemToDelete: (files: GlobalShoeBoxProps['folders'][0]['files']) => void
    setStagedShoeBoxItemToRename: (file: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => void
    setPickLenderLoan: (loan: GlobalShoeBoxProps['loans'][0] | null, files: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => void
    onCreateSharepointDocument: (element: Pick<ShoeboxItemRequestDto, 'documentType' | 'loanId' | 'uploadedById' | 'shoeboxOwnerId' | 'userId' | 'shoeboxType'>) => void
}>({
    moveToLoanPackage: {
        loanId: null,
        file: null,
    },
    stagedShoeBoxItemsToDelete: [],
    stagedShoeBoxItemToRename: null,
    pickLenderLoan: {
        loan: null,
        file: null,
    },
    user: null,
    onFilesDrop: async () => { },
    onMoveShoeboxItemsToUser: async () => { },
    onMoveItemsBackToLoanShoeBox: async () => { },
    onDownloadShoeboxItemsAsZip: () => { },
    onSetPickLenderLoan: async () => { },
    loans: [],
    onCreateSharepointDocument: async () => { },
    onMoveElementBackToShoeBox: async () => { },
    onMoveItemsBackToShoeBox: async () => { },
    onOpenShoeBoxFileInNewTab: () => { },
    me: null,
    onMoveShoeBoxFilesToLoan: async () => { },
    onAddFilesToUserShoeBox: async () => { },
    onRenameShoeBoxFile: async () => { },
    onConfirmDeleteShoeBoxItems: async () => { },
    setMoveToLoanShoeBoxFiles: () => { },
    setMoveToLoanPackage: () => { },
    setStagedShoeBoxItemToDelete: () => { },
    setStagedShoeBoxItemToRename: () => { },
    setPickLenderLoan: () => { },
});

const toastArgs = {
    autoClose: false as const,
    closeButton: false,
    toastId: UPLOADING_TOAST_ID,
    className: 'rounded-md bg-black-10 border border-gray-neutral-80',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    type: 'default' as const
}

type State = {
    moveToLoanPackage: {
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
        loanId: string | null
    },
    moveToLoanShoeBoxFiles: GlobalShoeBoxProps['folders'][0]['files']
    stagedShoeBoxItemsToDelete: GlobalShoeBoxProps['folders'][0]['files']
    stagedShoeBoxItemToRename: GlobalShoeBoxProps['folders'][0]['files'][0] | null
    pickLenderLoan: {
        loan: GlobalShoeBoxProps['loans'][0] | null,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
    }
};

// create reducer to handle actions with types
const initialState: State = {
    moveToLoanPackage: {
        file: null,
        loanId: null
    },
    moveToLoanShoeBoxFiles: [],
    stagedShoeBoxItemsToDelete: [],
    stagedShoeBoxItemToRename: null,
    pickLenderLoan: {
        loan: null,
        file: null,
    },
};

const actions = {
    SET_MOVE_TO_LOAN_SHOEBOX_FILES: 'SET_MOVE_TO_LOAN_SHOEBOX_FILES',
    SET_MOVE_TO_LOAN_PACKAGE_SHOEBOX_FILE: 'SET_MOVE_TO_LOAN_PACKAGE_SHOEBOX_FILE',
    SET_STAGED_SHOEBOX_ITEMS_TO_DELETE: 'SET_STAGED_SHOEBOX_ITEMS_TO_DELETE',
    SET_STAGED_SHOEBOX_ITEM_TO_RENAME: 'SET_STAGED_SHOEBOX_ITEM_TO_RENAME',
    SET_PICK_LENDER_LOAN: 'SET_PICK_LENDER_LOAN',
} as const;

type Action =
    | {
        type: typeof actions.SET_MOVE_TO_LOAN_PACKAGE_SHOEBOX_FILE; payload: {
            loanId: string | null,
            file: GlobalShoeBoxProps['folders'][0]['files'][0] | null
        }
    }
    | { type: typeof actions.SET_MOVE_TO_LOAN_SHOEBOX_FILES; payload: GlobalShoeBoxProps['folders'][0]['files'] }
    | { type: typeof actions.SET_STAGED_SHOEBOX_ITEMS_TO_DELETE; payload: GlobalShoeBoxProps['folders'][0]['files'] }
    | { type: typeof actions.SET_STAGED_SHOEBOX_ITEM_TO_RENAME; payload: GlobalShoeBoxProps['folders'][0]['files'][0] | null }
    | {
        type: typeof actions.SET_PICK_LENDER_LOAN; payload: {
            loan: GlobalShoeBoxProps['loans'][0] | null,
            file: GlobalShoeBoxProps['folders'][0]['files'][0] | null
        }
    }

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case actions.SET_MOVE_TO_LOAN_SHOEBOX_FILES:
            return {
                ...state,
                moveToLoanShoeBoxFiles: action.payload,
            };
        case actions.SET_MOVE_TO_LOAN_PACKAGE_SHOEBOX_FILE:
            return {
                ...state,
                moveToLoanPackage: action.payload,
            };
        case actions.SET_STAGED_SHOEBOX_ITEMS_TO_DELETE:
            return {
                ...state,
                stagedShoeBoxItemsToDelete: action.payload,
            };
        case actions.SET_STAGED_SHOEBOX_ITEM_TO_RENAME:
            return {
                ...state,
                stagedShoeBoxItemToRename: action.payload,
            };
        case actions.SET_PICK_LENDER_LOAN:
            return {
                ...state,
                pickLenderLoan: action.payload,
            };
        default:
            return state;
    }
};

export const ShoeBoxItemProvider = (props: React.PropsWithChildren<object>) => {
    const userState = useUser();
    const router = useRouter();
    const me = transformUser(userState.user);
    const [uploadingState, setUploadingState] = useState<Record<string, FileUploading>>({});
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [updateShoeBoxItem] = useUpdateShoeBoxItemMutation();
    const [createShoeboxItem] = useCreateShoeBoxItemMutation();
    const reduxDispatch = useDispatch();
    const [markShoeboxItemForDelete] = useMarkShoeboxItemForDeleteMutation();
    const [notifyCompanyShoebox] = useNotifyCompanyShoeBoxMutation();
    const [generateElementsZip] = useGenerateElementsZipMutation();
    const [generateZipUserShoeboxItems] = useGenerateZipUserShoeboxItemsMutation();

    const isTableAndDown = useMediaQuery('(max-width: 1023px)');
    // only get archive category for lenders
    const categories: LoanPhaseCategoryType[] = ['LEAD', 'ORIGINATION', 'PORTFOLIO',]
    if (userState.isLender) {
        categories.push('ARCHIVE')
    }
    const { data: loans = [], refetch: refetchLoans } = useGetLoansViewQuery({
        categories
    }, {
        skip: !userState.user,
    })

    const handleBulkCancelUploadingFile = useCallback((files: ToastFile[]) => {
        files.forEach((file) => {
            file.abortController.abort('all');
        })
    }, []);

    const createOrGetUploadToast = useCallback((files: File[], elements: ToastCreatingElement[], locations: string[]) => {
        const filesWithAbortControllers: ToastFile[] = files.map(file => {
            const abortController = new AbortController();
            const uniqueId = generateUUID();

            return ({
                uniqueId,
                reason: '',
                abortController: abortController,
                file,
                status: 'uploading' as const,
            }) as const;
        });

        const toast = document.querySelector('[role="alert"][aria-label="Uploading Toast"]')
        if (!toast) {
            reactToast(<UploadingToast
                locations={locations}
                onCancelAll={handleBulkCancelUploadingFile}
                elements={elements}
                files={filesWithAbortControllers}
            />, toastArgs)
        } else {
            reactToast.update(UPLOADING_TOAST_ID, {
                render: <UploadingToast
                    locations={locations}
                    elements={elements}
                    onCancelAll={handleBulkCancelUploadingFile}
                    files={filesWithAbortControllers}
                />
            })
        }
        return filesWithAbortControllers;
    }, [handleBulkCancelUploadingFile])

    const addFileToUploadToast = useCallback(async <T,>(uploadFile: ToastFile, promise: () => Promise<T>): Promise<{
        status: UploadStatus
        reason: string
    }> => {
        let uploadStatus: UploadStatus = "SUCCESS";
        let reason = '';
        // try generic promise
        try {
            await promise();
        } catch (error) {

            if (['single', 'all'].includes(error?.config?.signal?.reason)) {
                uploadStatus = "CANCEL";
                reason = getString('uploadCanceled');
            } else {
                uploadStatus = "ERROR";
                reason = error?.message || 'An error occurred';
            }
        }
        reactToast.update(UPLOADING_TOAST_ID, {
            render: <UploadingToast
                locations={[]}
                onCancelAll={handleBulkCancelUploadingFile}
                files={[{
                    ...uploadFile,
                    status: uploadStatus === 'SUCCESS' ? 'success' : 'error',
                    reason
                }]}
            />
        })
        return {
            status: uploadStatus,
            reason
        };
    }, [handleBulkCancelUploadingFile])

    const updateCompleteUploadToast = useCallback((files: ToastFile[], results: {
        status: UploadStatus
        reason: string
    }[]) => {
        reactToast.update(UPLOADING_TOAST_ID, {
            render: <UploadingToast
                locations={[]}
                onCancelAll={handleBulkCancelUploadingFile}
                files={files.map((file, index) => ({
                    ...file,
                    status: results[index].status === 'SUCCESS' ? 'success' : 'error',
                    reason: results[index].reason
                }))
                }
            />
        })
    }, [handleBulkCancelUploadingFile]);

    const onMoveShoeboxItemsToUser = useCallback(async (userId: string, userDisplayName, files: GlobalShoeBoxProps['folders'][0]['files']) => {
        const updatePromises = files.map((file) => updateShoeBoxItem({
            documentId: file.documentId,
            id: file.id,
            loanId: file.loanId,
            shoeboxOwnerId: userId,
            shoeboxType: 'LOAN' as const,
            title: file.title,
            newFile: null,
            documentType: null,
            uploadedById: file.uploadedById,
            userId,
        }))
        await Promise.all(updatePromises);
        reactToast.success(`${files.length} ${pluralize('item', files.length)} moved to ${userDisplayName} loan shoebox successfully`,
            {
                hideProgressBar: true,
                autoClose: TOAST_DURATION,
                closeOnClick: true,
                position: 'bottom-right',
                pauseOnHover: true,
            })
    }
        , [updateShoeBoxItem]);

    const onFilesDrop = useCallback(async (args: { files: File[], userId: string, loanId: string, location: string }) => {
        const otherFiles = args.files.filter((file) => !isZipFile(file));
        // get zip files to extract and get files from
        const zipFilePromises = args.files.filter(isZipFile)
            .map(async (file) => {
                try {
                    const { files: extractedFiles } = await getFoldersAndFiles(file);
                    return extractedFiles;
                } catch (error) {
                    toast({
                        type: 'error',
                        duration: DEFAULT_TOAST_DURATION,
                        content: `Failed to extract ${file.name} will be uploaded as zip.`
                    })
                    return [file];
                }
            });
        const zipFiles = await Promise.all(zipFilePromises);

        const allFiles = [
            ...otherFiles,
            ...zipFiles.flat()
        ];
        const filesWithAbortControllers: ToastFile[] = createOrGetUploadToast(allFiles, [], args.location ? [args.location] : []);
        const uploadPromises = filesWithAbortControllers.map(async (uploadFile) => {
            return addFileToUploadToast(uploadFile, async () => {
                if (args.loanId) {
                    await reduxDispatch(createLoanShoeBoxItem({
                        loanId: args.loanId,
                        file: uploadFile.file,
                        userId: args.userId,
                        signal: uploadFile.abortController.signal,
                    }))
                } else {
                    const uploadResult = await uploadFileToDocumentId({
                        signal: uploadFile.abortController.signal,
                        file: uploadFile.file,
                        pdfTronKey: '',
                    })
                    await createShoeboxItem({
                        documentId: uploadResult.documentId,
                        id: null,
                        loanId: null,
                        shoeboxOwnerId: args.userId,
                        shoeboxType: 'PERSONAL' as const,
                        title: uploadResult.file.name,
                        uploadedById: args.userId,
                        userId: args.userId,
                        newFile: null,
                        documentType: null,
                    })
                }
            })
        });

        const documentResults = await Promise.all(uploadPromises);
        updateCompleteUploadToast(filesWithAbortControllers, documentResults);
        if (args.loanId) {
            reduxDispatch(taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: args.loanId }]));
            reduxDispatch(getAllLoanUsersTasks(args.loanId));
        }
    }, [addFileToUploadToast, createOrGetUploadToast, createShoeboxItem, reduxDispatch, updateCompleteUploadToast]);


    const setStagedShoeBoxItemToDelete = (files: GlobalShoeBoxProps['folders'][0]['files']) => {
        dispatch({ type: actions.SET_STAGED_SHOEBOX_ITEMS_TO_DELETE, payload: files });
    }

    const setStagedShoeBoxItemToRename = (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => {
        dispatch({ type: actions.SET_STAGED_SHOEBOX_ITEM_TO_RENAME, payload: file });
    }

    const setPickLenderLoan = (loan: GlobalShoeBoxProps['loans'][0] | null, file: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => {
        dispatch({ type: actions.SET_PICK_LENDER_LOAN, payload: { loan, file } });
    }

    const setMoveToLoanShoeBoxFiles = useCallback((file: GlobalShoeBoxProps['folders'][0]['files']) => {
        dispatch({ type: actions.SET_MOVE_TO_LOAN_SHOEBOX_FILES, payload: file });
        refetchLoans()
    }, [refetchLoans]);

    const setMoveToLoanPackage = useCallback((loanId: string | null, file: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => {
        dispatch({
            type: actions.SET_MOVE_TO_LOAN_PACKAGE_SHOEBOX_FILE,
            payload: {
                file,
                loanId
            }
        });
    }, []);

    const onAddMeToLoan = useCallback(async (loanId: string) => {
        await api.addLender(loanId, {
            appUser: me.id,
            contactVisibleToBorrowers: false,
            contactVisibleToLenders: false,
            canAcceptFiles: null,
            leadBorrower: null,
            leadLender: null,
            newToLoan: true,
            borrowerType: null,
            role: userState.user.loggedCompanyRole,
            visibleToBorrower: null,
            contactRelation: null,
        })
    }, [me?.id, userState.user?.loggedCompanyRole]);

    const refreshLoan = useCallback(async (loanId: string) => {
        reduxDispatch(getLoan(loanId));
        reduxDispatch(getLoanShoeBoxItems(loanId));
    }, [reduxDispatch]);

    const moveShoeBoxFilesToLoan = useCallback(async (
        loan: GlobalShoeBoxProps['loans'][0],
        userId: string,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null) => {
        await updateShoeBoxItem({
            documentId: file.documentId,
            id: file.id,
            loanId: loan.id,
            shoeboxOwnerId: userId,
            shoeboxType: 'LOAN',
            title: file.title,
            uploadedById: file.uploadedById,
            userId,
            newFile: null,
            documentType: null,
        })
        if (file.loanId) {
            refreshLoan(file.loanId);
        }
        toast({
            type: 'success',
            duration: TOAST_DURATION,
            content: `${file.title} added to ${loan.shortCode.toUpperCase()} ${loan.projectName} successfully`
        })
    }, [updateShoeBoxItem, refreshLoan]);

    const onMoveShoeBoxFilesToLoan = useCallback(async (
        loan: GlobalShoeBoxProps['loans'][0],
        user: GlobalShoeBoxProps['loans'][0]['users'][0],
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
    ) => {
        setPickLenderLoan(null, null);
        setMoveToLoanShoeBoxFiles([])
        try {
            if (user.id === me.id && !loan.users.some((loanUser) => loanUser.id === me.id)) {
                // wait for user to be added to loan
                await onAddMeToLoan(loan.id);
            }
            await moveShoeBoxFilesToLoan(loan, user.id, file);
        } catch (error) {
            console.error(error);
        }
    }, [setMoveToLoanShoeBoxFiles, me?.id, moveShoeBoxFilesToLoan, onAddMeToLoan]);

    const onCreateSharepointDocument = useCallback(async (element: Pick<ShoeboxItemRequestDto, 'documentType' | 'loanId' | 'shoeboxType' | 'uploadedById' | 'shoeboxOwnerId' | 'userId'>) => {
        let extension = 'docx';
        let title = 'Document';
        if (element.documentType === 'EXCEL') {
            extension = 'xlsx';
            title = 'Book';
        } else if (element.documentType === 'POWERPOINT') {
            extension = 'pptx';
            title = 'Presentation';
        }
        const toastElement: ToastCreatingElement = {
            id: generateUUID(),
            title: `${title}.${extension}`,
            status: 'uploading',
        }
        createOrGetUploadToast([], [toastElement], []);
        await createShoeboxItem({
            documentId: null,
            id: null,
            loanId: element.loanId,
            shoeboxOwnerId: element.shoeboxOwnerId,
            shoeboxType: element.shoeboxType,
            title: null,
            uploadedById: element.uploadedById,
            userId: element.userId,
            newFile: true,
            documentType: element.documentType,
        })

        reactToast.update(UPLOADING_TOAST_ID, {
            render: <UploadingToast
                locations={[]}
                elements={[{
                    ...toastElement,
                    status: 'success',
                }]}
                onCancelAll={handleBulkCancelUploadingFile}
                files={[]}
            />
        })

    }, [createOrGetUploadToast, createShoeboxItem, handleBulkCancelUploadingFile])

    const onSetPickLenderLoan = useCallback(async (
        loan: GlobalShoeBoxProps['loans'][0] | null,
        file: GlobalShoeBoxProps['folders'][0]['files'][0] | null,
    ) => {
        if (loan?.users.some((user) => user.id === me.id)) {
            await onMoveShoeBoxFilesToLoan(loan, me, file);
        } else {
            setPickLenderLoan(loan, file);
        }
    }, [me, onMoveShoeBoxFilesToLoan]);

    const onOpenShoeBoxFileInNewTab = (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => {
        try {
            // parse search params from window.search
            const searchParams = new URLSearchParams(window.location.search);
            // create a new URL object with the current origin and pathname
            const url = new URL(`${window.location.origin}${window.location.pathname}`);
            [...searchParams.entries()].forEach(([key, value]) => {
                // set the query params
                url.searchParams.set(key, value);
            })
            url.searchParams.set(QUERY_SHOEBOX_ITEM_PREVIEW_ID, file.id);
            // open the url in a new tab
            window.open(url.toString(), '_blank');
        } catch (error) {
            console.error(error);
        }
    }

    const onConfirmDeleteShoeBoxItems = useCallback(async (files: GlobalShoeBoxProps['folders'][0]['files']) => {
        const [file] = files
        await Promise.all(files.map((file) => markShoeboxItemForDelete({
            id: file.id
        })))
        if (file.loanId) {
            refreshLoan(file.loanId);
        }
        if (files.length === 1) {
            toast({
                type: 'success',
                duration: TOAST_DURATION,
                content: `${file.title} deleted successfully`
            })
        } else {
            toast({
                type: 'success',
                duration: TOAST_DURATION,
                content: `${files.length} ${pluralize('item', files.length)} deleted successfully`
            })
        }
        // remove the query param
        router.replace({
            pathname: router.pathname,
            query: {
                ...router.query,
                [QUERY_SHOEBOX_ITEM_PREVIEW_ID]: undefined
            }
        })
    }, [markShoeboxItemForDelete, refreshLoan, router]);

    const onRenameShoeBoxFile = useCallback(async (file: GlobalShoeBoxProps['folders'][0]['files'][0], newName: string) => {
        await updateShoeBoxItem({
            documentId: file.documentId,
            id: file.id,
            loanId: file.loanId,
            shoeboxOwnerId: file.ownerId,
            shoeboxType: file.shoeboxType,
            title: newName,
            uploadedById: file.uploadedById,
            userId: file.ownerId,
            newFile: null,
            documentType: null,
        })
        if (file.loanId) {
            refreshLoan(file.loanId);
        }
        toast({
            type: 'success',
            duration: TOAST_DURATION,
            content: `${file.title} renamed to ${newName} successfully`
        })
    }, [refreshLoan, updateShoeBoxItem]);

    const onMoveItemsBackToShoeBox = useCallback(async (files: GlobalShoeBoxProps['folders'][0]['files']) => {
        const [firstFile] = files;
        const updatePromises = files.map((file) => updateShoeBoxItem({
            documentId: file.documentId,
            id: file.id,
            loanId: firstFile.loanId,
            shoeboxOwnerId: userState.user.id,
            shoeboxType: 'PERSONAL' as const,
            title: file.title,
            uploadedById: file.uploadedById ?? userState.user.id,
            userId: userState.user.id,
            newFile: null,
            documentType: null,
        }))
        await Promise.all(updatePromises);
        if (firstFile.loanId) {
            refreshLoan(firstFile.loanId)
        }
        notifyCompanyShoebox({
            companyId: userState.company?.id
        })
        reactToast.success(`${files.length} ${pluralize('item', files.length)} moved to your shoebox successfully`,
            {
                hideProgressBar: true,
                autoClose: TOAST_DURATION,
                closeOnClick: true,
                position: 'bottom-right',
                pauseOnHover: true,
            })
        // if we are on tablet or mobile we need to open my shoebox
        if (isTableAndDown) {
            reduxDispatch(toggleDrawer(true));
        }

    }, [isTableAndDown, notifyCompanyShoebox, reduxDispatch, refreshLoan, updateShoeBoxItem, userState.company?.id, userState.user?.id]);

    const onMoveItemsBackToLoanShoeBox = useCallback(async (files: GlobalShoeBoxProps['folders'][0]['files'], userDisplayName: string) => {
        const updatePromises = files.map((file) => createShoeboxItem({
            documentId: file.documentId,
            id: null,
            loanId: file.loanId,
            shoeboxOwnerId: file.ownerId,
            shoeboxType: file.shoeboxType,
            title: file.title,
            uploadedById: file.uploadedById ?? file.ownerId,
            userId: file.ownerId,
            newFile: null,
            documentType: null,
        }))
        await Promise.all(updatePromises);
        reactToast.success(`${files.length} ${pluralize('item', files.length)} moved to ${userDisplayName} loan shoebox successfully`,
            {
                hideProgressBar: true,
                autoClose: TOAST_DURATION,
                closeOnClick: true,
                position: 'bottom-right',
                pauseOnHover: true,
            })

    }, [createShoeboxItem]);

    const onAddFilesToUserShoeBox = useCallback(async (files: File[], rejectedFiles: File[], userId: string) => {
        const otherFiles = files.filter((file) => !isZipFile(file));
        // get zip files to extract and get files from
        const zipFilePromises = files.filter(isZipFile)
            .map(async (file) => {
                try {
                    const { files: extractedFiles } = await getFoldersAndFiles(file);
                    return extractedFiles;
                } catch (error) {
                    toast({
                        type: 'error',
                        duration: TOAST_DURATION,
                        content: `Failed to extract ${file.name} will be uploaded as zip.`
                    })
                    return [file];
                }
            });
        const zipFiles = await Promise.all(zipFilePromises);

        const allFiles = [
            ...otherFiles,
            ...zipFiles.flat()
        ];

        const filesWithAbortControllers = allFiles
            .map((file) => {
                const abortController = new AbortController();
                const uniqueId = generateUUID();

                setUploadingState(prevState => ({
                    ...prevState,
                    [uniqueId]: {
                        id: uniqueId,
                        name: file.name,
                        progress: 0,
                        abortController: abortController
                    }
                }))

                return ({
                    uniqueId,
                    reason: '',
                    abortController: abortController,
                    file,
                    status: 'uploading' as const,
                })
            })
        if (filesWithAbortControllers.length > 0) {
            const toastArgs = {
                autoClose: false as const,
                closeButton: false,
                toastId: UPLOADING_TOAST_ID,
                updateId: UPLOADING_TOAST_ID,
                className: 'rounded-md bg-black-10',
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                type: 'default' as const
            }

            if (!reactToast.isActive(UPLOADING_TOAST_ID)) {
                reactToast(<UploadingToast
                    onCancelAll={handleBulkCancelUploadingFile}
                    locations={['my shoebox']}
                    files={filesWithAbortControllers}
                />,
                    toastArgs)
            } else {
                reactToast.update(UPLOADING_TOAST_ID, {
                    render: <UploadingToast
                        locations={['my shoebox']}
                        onCancelAll={handleBulkCancelUploadingFile}
                        files={filesWithAbortControllers}
                    />,
                    ...toastArgs
                })
            }
        }
        // upload file and get document id back
        const createDocumentPromises = filesWithAbortControllers
            .map<Promise<UploadStatus>>(async (file, fileIndex) => {
                let uploadStatus: UploadStatus = "SUCCESS";

                const result = {
                    documentId: '',
                    file: file.file
                }
                try {
                    const uploadResult = await uploadFileToDocumentId({
                        signal: file.abortController.signal,
                        file: file.file,
                        pdfTronKey: '',
                        progress: (percent) => setUploadingState(prevState => ({
                            ...prevState,
                            [file.uniqueId]: {
                                ...prevState[file.uniqueId],
                                progress: percent
                            }

                        }))

                    })
                    await createShoeboxItem({
                        documentId: uploadResult.documentId,
                        id: null,
                        loanId: null,
                        shoeboxOwnerId: userId,
                        shoeboxType: 'PERSONAL' as const,
                        title: uploadResult.file.name,
                        uploadedById: userId,
                        userId: userId,
                        newFile: null,
                        documentType: null,
                    })

                    result.documentId = uploadResult.documentId
                    result.file = uploadResult.file
                    uploadStatus = "SUCCESS";
                } catch (error) {
                    if (error?.config?.signal?.reason !== AbortReason.ALL) {
                        toast({
                            type: 'error',
                            duration: TOAST_DURATION,
                            content: "Upload canceled"
                        });
                    }
                    if ([AbortReason.SINGLE, AbortReason.ALL].includes(error?.config?.signal?.reason)) {
                        uploadStatus = "CANCEL";
                    } else {
                        uploadStatus = "ERROR";
                    }

                } finally {
                    setUploadingState(prevState => ({
                        ...prevState,
                        [file.uniqueId]: undefined
                    }))
                }

                reactToast.update(UPLOADING_TOAST_ID, {
                    render: <UploadingToast
                        locations={['my shoebox']}
                        onCancelAll={handleBulkCancelUploadingFile}
                        files={filesWithAbortControllers.map((file, index) => ({
                            ...file,
                            ...(index === fileIndex ? {
                                status: uploadStatus === 'SUCCESS' ? 'success' : 'error'
                            } : {})
                        }))}
                    />,
                    type: 'default'
                })
                return uploadStatus;

            });

        const documentResults = await Promise.all(createDocumentPromises);
        notifyCompanyShoebox({
            companyId: userState.company?.id
        })
        reactToast.update(UPLOADING_TOAST_ID, {
            render: <UploadingToast
                locations={['my shoebox']}
                onCancelAll={handleBulkCancelUploadingFile}
                files={filesWithAbortControllers.map((file, index) => ({
                    ...file,
                    status: documentResults[index] === 'SUCCESS' ? 'success' : 'error'
                }))
                }
            />,
            type: 'default'
        })
        const erroredUploads = documentResults.filter((result) => result === 'ERROR').length;
        if (erroredUploads) {
            toast({
                type: 'error',
                duration: TOAST_DURATION,
                content: `${erroredUploads} ${pluralize('item', erroredUploads)} failed to upload`
            })
        }
        if (rejectedFiles.length) {
            // setRejectedFiles(rejectedFiles);
        }
    }, [createShoeboxItem, handleBulkCancelUploadingFile, notifyCompanyShoebox, userState.company?.id]);

    const onMoveElementBackToShoeBox = useCallback(async (files: GlobalShoeBoxProps['folders'][0]['files']) => {
        const [firstFile] = files;
        const updatePromises = files.map((file) => createShoeboxItem({
            documentId: file.documentId,
            id: null,
            loanId: file.loanId,
            shoeboxOwnerId: userState.user.id,
            shoeboxType: 'PERSONAL' as const,
            title: file.title,
            uploadedById: file.uploadedById ?? userState.user.id,
            userId: userState.user.id,
            newFile: null,
            documentType: null,
        }))
        await Promise.all(updatePromises);
        if (firstFile.loanId) {
            refreshLoan(firstFile.loanId)
        }
        notifyCompanyShoebox({
            companyId: userState.company?.id
        })
        reactToast.success(`${files.length} ${pluralize('item', files.length)} moved to your shoebox successfully`,
            {
                hideProgressBar: true,
                autoClose: TOAST_DURATION,
                closeOnClick: true,
                position: 'bottom-right',
                pauseOnHover: true,
            })

        // if we are on tablet or mobile we need to open my shoebox
        if (isTableAndDown) {
            reduxDispatch(toggleDrawer(true));
        }

    }, [createShoeboxItem, isTableAndDown, notifyCompanyShoebox, reduxDispatch, refreshLoan, userState.company?.id, userState.user?.id]);

    const onDownloadShoeboxItemsAsZip = useCallback(async (files: GlobalShoeBoxProps['folders'][0]['files']) => {
        const filesWithLoanId = files.filter((file) => file.loanId);
        const filesWithoutLoanId = files.filter((file) => !file.loanId);

        if (filesWithLoanId.length > 0) {
            const [firstFile] = filesWithLoanId;
            await generateElementsZip({
                loanId: firstFile.loanId,
                data: {
                    id: null,
                    elementList: [],
                    shoeboxItemsIds: filesWithLoanId.map((file) => file.id),
                    loanId: firstFile.loanId,
                    userId: userState.user.id,
                    itemsIgnored: 0,
                    itemsZipped: filesWithLoanId.length,
                    createZip: true,
                    createPdf: false,
                }
            })
        }
        if (filesWithoutLoanId.length > 0) {
            await generateZipUserShoeboxItems({
                id: null,
                elementList: [],
                shoeboxItemsIds: filesWithoutLoanId.map((file) => file.id),
                loanId: null,
                userId: userState.user.id,
                itemsIgnored: 0,
                itemsZipped: filesWithoutLoanId.length,
                createZip: true,
                createPdf: false,
            })
        }
    }, [generateElementsZip, generateZipUserShoeboxItems, userState.user?.id]);

    const filteredLoans = loans.filter(filterActivePhaseCategory);
    const mappedLoans = transformedLoanDtoToLoan(filteredLoans, userState.user?.id);


    const value = useMemo(() => {
        return {
            ...state,
            me,
            user: userState.user,
            loans: mappedLoans,
            setMoveToLoanShoeBoxFiles,
            setMoveToLoanPackage,
            onOpenShoeBoxFileInNewTab,
            setStagedShoeBoxItemToDelete,
            setStagedShoeBoxItemToRename,
            setPickLenderLoan,
            onRenameShoeBoxFile,
            onSetPickLenderLoan,
            onMoveShoeBoxFilesToLoan,
            onDownloadShoeboxItemsAsZip,
            onConfirmDeleteShoeBoxItems,
            onMoveItemsBackToShoeBox,
            onMoveItemsBackToLoanShoeBox,
            onMoveShoeboxItemsToUser,
            onMoveElementBackToShoeBox,
            onAddFilesToUserShoeBox,
            onFilesDrop,
            onCreateSharepointDocument,
        };
    }, [state, me, userState.user, mappedLoans, setMoveToLoanShoeBoxFiles, setMoveToLoanPackage, onRenameShoeBoxFile, onSetPickLenderLoan, onMoveShoeBoxFilesToLoan, onDownloadShoeboxItemsAsZip, onConfirmDeleteShoeBoxItems, onMoveItemsBackToShoeBox, onMoveItemsBackToLoanShoeBox, onMoveShoeboxItemsToUser, onMoveElementBackToShoeBox, onAddFilesToUserShoeBox, onFilesDrop, onCreateSharepointDocument]);

    return (
        <ShoeboxItemViewerContext.Provider value={value}>
            {props.children}
            {state.moveToLoanShoeBoxFiles.length > 0 && <PickLoanToMoveToConfirmation
                hideLoanFilter={userState.isBorrower}
                scrollableTarget='shoebox-item-viewer-scrollable-target-shoebox-item'
                items={state.moveToLoanShoeBoxFiles}
                open
                loans={mappedLoans}
                onOpenChange={(open) => {
                    if (!open) {
                        setMoveToLoanShoeBoxFiles([])
                    }
                }}
                onMoveConfirm={(loan, [item]) => {
                    onSetPickLenderLoan(
                        loan,
                        item,
                    );
                }}
            />}
            {!!state.pickLenderLoan.loan && <AddFilesToUserConfirmation
                open
                onOpenChange={(open) => {
                    if (!open) {
                        setPickLenderLoan(null, null)
                    }
                }}
                loan={state.pickLenderLoan.loan}
                items={[state.pickLenderLoan.file]}
                me={me}
                onConfirm={(loan, user, [item]) => {
                    onMoveShoeBoxFilesToLoan(loan, user, item);
                }}
            />}
            {state.stagedShoeBoxItemsToDelete.length > 0 && <ActionAlertDialog
                ariaLabel={"Are you sure you want to delete"}
                message={`Are you sure you want to delete ${state.stagedShoeBoxItemsToDelete.length > 1 ? `${state.stagedShoeBoxItemsToDelete.length} items` : state.stagedShoeBoxItemsToDelete[0].title}?`}
                open
                variant='danger'
                onConfirm={() => {
                    onConfirmDeleteShoeBoxItems(state.stagedShoeBoxItemsToDelete);
                }}
                onOpenChange={(open) => {
                    if (!open) {
                        setStagedShoeBoxItemToDelete([])
                    }
                }}
            />}
            {!!state.stagedShoeBoxItemToRename && <RenameAlertDialog
                open
                onOpenChange={(open) => {
                    if (!open) {
                        setStagedShoeBoxItemToRename(null)
                    }
                }}
                onRename={(newName) => {
                    onRenameShoeBoxFile(state.stagedShoeBoxItemToRename, newName);
                }}
                name={state.stagedShoeBoxItemToRename?.title || ""}
            />}
            {state.moveToLoanPackage.loanId && <MoveFormElementsDialog
                open={!!state.moveToLoanPackage.loanId}
                loanId={state.moveToLoanPackage.loanId}
                onOpenChange={(open) => {
                    if (!open) {
                        setMoveToLoanPackage(null, null)
                    }
                }}
                type="shoeboxItem"
                shoeBoxFiles={[state.moveToLoanPackage.file]} />}
        </ShoeboxItemViewerContext.Provider>
    );
};

export const useShoeBoxItemViewerContext = () => {
    const context = React.useContext(ShoeboxItemViewerContext);
    if (context === undefined) {
        throw new Error(
            'useShoeBoxItemViewerContext must be used within a ShoeBoxItemProvider',
        );
    }
    return context;
};
