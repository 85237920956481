import { j as e } from "./jsx-runtime-D0AQ1Mn7.js";
import { useState as c } from "react";
import { A as r } from "./AlertDialog.component-C6-J4wlj.js";
import { B as i } from "./Breadcrumb.component-q0ySMoGh.js";
import { B as x } from "./Button-BOVHAiPs.js";
import { S as t } from "./Separator.component-C8L6rMW3.js";
import { S as h } from "./Stack-BZqOy47V.js";
import { T as a } from "./Text-B4fIcnEL.js";
import { F as d } from "./FolderIcon.component-CylUmsY3.js";
import { c as n } from "./utils-CJ9afRe1.js";
const y = (o) => {
  const [s, m] = c(null);
  return /* @__PURE__ */ e.jsxs(
    r,
    {
      open: o.open,
      onOpenChange: o.onOpenChange,
      children: [
        /* @__PURE__ */ e.jsx(r.Overlay, {}),
        /* @__PURE__ */ e.jsxs(r.Content, { className: "sm:max-w-2xl lg:max-w-2xl gap-0 w-full", children: [
          /* @__PURE__ */ e.jsx(r.VisuallyHidden, { children: /* @__PURE__ */ e.jsx(r.Title, { children: "Move to loan" }) }),
          /* @__PURE__ */ e.jsxs(a, { className: "px-3 pt-3", size: "sm", children: [
            "Move ",
            o.items.length,
            " items to loan"
          ] }),
          /* @__PURE__ */ e.jsx(t, {}),
          /* @__PURE__ */ e.jsx(i, { className: "bg-black-10 p-2", children: /* @__PURE__ */ e.jsxs(i.List, { children: [
            /* @__PURE__ */ e.jsx(i.Separator, {}),
            /* @__PURE__ */ e.jsx(i.Item, { className: "text-black-primary text-sm", children: "Loans" })
          ] }) }),
          /* @__PURE__ */ e.jsx(
            "div",
            {
              className: "px-4 flex gap-6 sm:gap-3 flex-col h-full sm:h-72 sm:max-h-full overflow-y-auto scrollbar-stable py-2",
              children: /* @__PURE__ */ e.jsx(
                "div",
                {
                  className: n("flex-1 flex flex-col gap-3"),
                  children: o.loans.map((l) => /* @__PURE__ */ e.jsxs(
                    h,
                    {
                      onClick: () => m((s == null ? void 0 : s.id) === l.id ? null : l),
                      row: !0,
                      space: "sm",
                      className: n(
                        "px-3 py-2 group flex min-h-8 items-center hover:bg-blue-50 rounded-lg transition-colors cursor-pointer",
                        { "bg-blue-50": (s == null ? void 0 : s.id) === l.id }
                      ),
                      children: [
                        /* @__PURE__ */ e.jsx(d, { className: "shrink-0" }),
                        /* @__PURE__ */ e.jsxs("div", { className: "flex-1 overflow-hidden flex flex-row gap-2", children: [
                          /* @__PURE__ */ e.jsx(a, { truncate: !0, size: "sm", children: l.projectName }),
                          /* @__PURE__ */ e.jsx(a, { size: "sm", variant: "secondary", children: l.shortCode })
                        ] }),
                        /* @__PURE__ */ e.jsx(r.Cancel, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                          x,
                          {
                            onClick: () => {
                              o.onMoveConfirm(
                                l,
                                o.items
                              );
                            },
                            size: "sm",
                            className: "hidden text-blue-100 hover:text-blue-100 hover:bg-blue-100 hover:bg-opacity-10 sm:inline-flex border-0 py-2 opacity-0 group-hover:opacity-100 bg-opacity-20 w-0 h-8 group-hover:w-auto group-hover:px-2 overflow-hidden p-0",
                            children: "Move here"
                          }
                        ) })
                      ]
                    },
                    l.id
                  ))
                }
              )
            }
          ),
          /* @__PURE__ */ e.jsx(t, {}),
          /* @__PURE__ */ e.jsxs(r.Footer, { className: "shrink-0", children: [
            /* @__PURE__ */ e.jsx(r.Cancel, { children: "Cancel" }),
            /* @__PURE__ */ e.jsxs(
              r.Action,
              {
                className: "w-full max-w-44 truncate block",
                onClick: () => s && o.onMoveConfirm(
                  s,
                  o.items
                ),
                disabled: !s,
                children: [
                  "Move to...",
                  (s == null ? void 0 : s.projectName) ?? ""
                ]
              }
            )
          ] })
        ] })
      ]
    }
  );
};
export {
  y as P
};
