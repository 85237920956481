import { useContext, useEffect, useState } from "react";
import { api } from "src/api";
import { DocumentAnswerV2Dto, FormElementV2ResponseDto } from "src/backend";
import { FormElementContext } from "src/contexts/form-element-context";
import { useMarkShoeboxItemForDeleteMutation } from "src/services/lenderShoeBoxApi";
import { addDocumentSection, getLoanFormElements } from "src/slices/form-element";
import { useDispatch } from "src/store";
import { sortFromElements } from "src/utils/form-element-transformer";
import { toast } from "src/utils/toast";

import { ExtendedFormElementV2ResponseDto, MoveDialogProps } from "./move-form-elements-dialog.types";

const sortElementsAlphabetically = (a: ExtendedFormElementV2ResponseDto, b: ExtendedFormElementV2ResponseDto) => {
    return String(a.title).localeCompare(String(b.title), 'en', {
        sensitivity: 'base',
        ignorePunctuation: true,
        numeric: true
    });
}

// recursive function to build the tree from the form elements
const buildTree = (elements: Record<string, ExtendedFormElementV2ResponseDto>, childrenIds: string[]): ExtendedFormElementV2ResponseDto[] => {
    const children = childrenIds.filter(id => elements[id])
        .map((id) => elements[id])
        // remove duplicates
        .filter((element, index, self) => self.findIndex((e) => e.id === element.id) === index)
        .sort(sortElementsAlphabetically);

    return children.map((child) => {
        return {
            ...child,
            children: buildTree(elements, child.childrenIds),
        }
    });
}

export const useMoveFormElementsDialog = (props: MoveDialogProps) => {
    const [allFormElements, setAllFormElements] = useState<FormElementV2ResponseDto[]>([]);
    const [formElementsTree, setFormElementsTree] = useState<ExtendedFormElementV2ResponseDto[]>([]);
    const [selectedFolderId, setSelectedFolderId] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(props.open || false);
    const formElementContextState = useContext(FormElementContext);
    const [markShoeboxItemForDelete] = useMarkShoeboxItemForDeleteMutation();
    const dispatch = useDispatch();
    useEffect(() => {
        api.getV2FormElements({
            loanId: props.loanId,
        }).then((formElements) => {
            const { packageInfo, elements } = formElements;
            // elements list
            const elementsList = Object.values(elements);
            // find the root element
            const rootElement = elementsList.find((element) => !element.parentId);
            // packageInfo list
            const packageInfoList = Object.values(packageInfo);
            // find the root package info
            const rootPackageInfo = packageInfoList.find((info) => info.elementId === rootElement.id);
            // mapped package info with the element
            const mappedPackageInfoToList: FormElementV2ResponseDto[] = packageInfoList.map((info) => ({
                ...elements[info.elementId],
                ...info,
            }));
            // get only folder package infos
            const folderPackageInfos = packageInfoList
                .filter((info) => elements[info.elementId].storageType === "FOLDER")
                .reduce((acc, info) => {
                    acc[info.id] = {
                        ...elements[info.elementId],
                        ...info,
                        children: []
                    };
                    return acc;
                }, {} as Record<string, ExtendedFormElementV2ResponseDto>);
            // build the tree
            const formElementsTree = buildTree(folderPackageInfos, rootPackageInfo ? [rootPackageInfo.id] : [])

            setAllFormElements(mappedPackageInfoToList);
            // @ts-expect-error
            setFormElementsTree(formElementsTree.sort(sortFromElements));
        });
    }, [props.loanId])

    const onAddShoeBoxItemToPackageFolder = async (args: { loanId: string, shoeboxItemId: string, documentTitle: string, documentId: string, formElementId: string, formElementTitle: string }) => {
        try {
            await dispatch((addDocumentSection({
                loanId: args.loanId,
                formElementId: args.formElementId,
                document: { id: args.documentId, name: args.documentTitle } as DocumentAnswerV2Dto,
            })))
            await markShoeboxItemForDelete({ id: args.shoeboxItemId });

            toast({
                content: `${args.documentTitle} moved to ${args.formElementTitle}`,
                type: 'success',
            })
            dispatch(getLoanFormElements(args.loanId))
        } catch (error) {
            toast({
                content: 'Unable to add document',
                type: 'error',
            })
        }
    }
    const handleMoveClick = async (targetFolderId: string) => {
        setIsLoading(true);
        const selectedFormElement = allFormElements.find((element) => element.id === targetFolderId);
        if (props.type === "formElement") {
            await formElementContextState.onMoveFormElements(props.formElements, selectedFormElement);
        } else {
            const [firstShoeBoxItem] = props.shoeBoxFiles;
            await onAddShoeBoxItemToPackageFolder({
                loanId: props.loanId,
                documentId: firstShoeBoxItem.documentId,
                documentTitle: firstShoeBoxItem.title,
                shoeboxItemId: firstShoeBoxItem.id,
                formElementId: selectedFormElement?.id,
                formElementTitle: selectedFormElement?.title,
            })
        }
        props.onOpenChange?.(false);
        setIsLoading(false);
        setIsDialogOpen(false);
    }

    const handleSelect = (folderId: string) => {
        setSelectedFolderId(folderId)
    }

    const onOpenChange = (open: boolean) => {
        props.onOpenChange?.(open);
        setIsDialogOpen(open)
    }
    const totalItems = props.type === "formElement" ? props.formElements.length : props.shoeBoxFiles.length;
    const singleItemTitle = props.type === "formElement" ? props.formElements?.[0]?.title : props.shoeBoxFiles?.[0]?.title;
    return {
        totalItems,
        singleItemTitle,
        formElementsTree,
        onSelect: handleSelect,
        onMoveClick: handleMoveClick,
        selectedFolderId,
        isDialogOpen,
        isLoading,
        onOpenChange,
        locationTitle: props.type === "formElement" ? formElementContextState.activeFormElementFolder?.title : "Shoebox",
    }
};